<template>
  <validation-observer ref="sale-invoice-profile-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col sm="12">
          <validation-provider
            v-slot="validationContext"
            name="Tipo de factura"
            rules="required"
          >
            <b-form-group>
              <b-form-radio-group
                v-model="model.sale_invoice_category"
                :options="saleInvoiceTypes"
                :state="$getValidationState(validationContext)"
                @input="update('sale_invoice_category', $event)"
                @change="handleSaleInvoiceCategoryChange"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="División"
                rules="required"
              >
                <b-form-group
                  label="División"
                  label-for="sale-invoice-division"
                >
                  <BaseSelect
                    id="sale-invoice-division"
                    :value="model.division"
                    :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
                    http-method="get"
                    @input="update('division', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="4">
              <validation-provider
                v-slot="validationContext"
                name="Serie"
                rules="required"
              >
                <b-form-group
                  label="Serie"
                  label-for="sale-invoice-serie"
                >
                  <v-select
                    id="sale-invoice-serie"
                    :value="model.serie"
                    :options="model.series || series"
                    @input="handleInputSerie"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="4">
              <validation-provider
                v-slot="validationContext"
                name="Número"
                rules="required"
              >
                <b-form-group
                  label="Número"
                  label-for="sale-invoice-number"
                >
                  <b-form-input
                    id="sale-invoice-number"
                    ref="sale-invoice-number"
                    :value="model.number"
                    name="sale-invoice-number"
                    placeholder="Número"
                    :state="$getValidationState(validationContext) && numberInputState"
                    :disabled="numberInputDisabled"
                    @input="update('number', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="Fecha factura"
                rules="required"
              >
                <b-form-group
                  label="Fecha factura"
                  label-for="sale-invoice-date"
                >
                  <b-form-datepicker
                    id="sale-invoice-date"
                    :value="model.invoice_date"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    size="sm"
                    reset-button
                    :hide-header="true"
                    placeholder="Fecha factura"
                    :label-help="null"
                    :state="$getValidationState(validationContext)"
                    :max="model.invoice_due_date"
                    start-weekday="1"
                    @input="update('invoice_date', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="Vencimiento factura"
                rules="required"
              >
                <b-form-group
                  label="Vencimiento factura"
                  label-for="sale-invoice-due-date"
                >
                  <b-form-datepicker
                    id="sale-invoice-due-date"
                    :value="model.invoice_due_date"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    size="sm"
                    reset-button
                    :hide-header="true"
                    placeholder="Vencimiento factura"
                    :label-help="null"
                    :min="model.invoice_date"
                    :state="$getValidationState(validationContext)"
                    start-weekday="1"
                    @input="update('invoice_due_date', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Fecha cobro"
                label-for="sale-invoice-collection-date"
              >
                <b-form-datepicker
                  id="sale-invoice-collection-date"
                  :value="model.invoice_collection"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha cobro"
                  :label-help="null"
                  :max="model.invoice_collection_date"
                  start-weekday="1"
                  @input="update('invoice_collection', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Vencimiento cobro"
                label-for="sale-invoice-collection-due-date"
              >
                <b-form-datepicker
                  id="sale-invoice-collection-due-date"
                  :value="model.invoice_collection_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Vencimiento cobro"
                  :label-help="null"
                  :min="model.invoice_collection"
                  start-weekday="1"
                  @input="update('invoice_collection_date', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Estado"
                rules="required"
              >
                <b-form-group
                  label="Estado"
                  label-for="sale-invoice-status"
                >
                  <StatusSelect
                    id="sale-invoice-status"
                    :value="model.status"
                    :type="statusType"
                    @selection-change="update('status', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Certificación"
                label-for="sale-invoice-certification"
              >
                <b-form-input
                  id="sale-invoice-certification"
                  :value="model.certification"
                  name="sale-invoice-certification"
                  placeholder="Certificación"
                  @input="update('certification', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Tipología de factura"
                label-for="sale-invoice-type"
              >
                <BaseSelect
                  id="sale-invoice-type"
                  :resource="$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_TYPES"
                  http-method="get"
                  :value="model.sale_invoice_type"
                  @input="update('sale_invoice_type', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!jobId"
              sm="12"
              class="pb-2"
            >
              <b-form-checkbox
                v-model="model.no_invoice"
                value="1"
                :unchecked-value="0"
                @input="update('no_invoice', $event)"
              >
                No facturas
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Cliente"
                rules="required"
              >
                <b-form-group
                  label="Cliente"
                  label-for="sale-invoice-client"
                >
                  <ClientSelect
                    id="sale-invoice-client"
                    :value="model.client"
                    @input="update('client', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Contacto de facturación"
                label-for="sale-invoice-contact"
              >
                <BaseSelect
                  id="sale-invoice-contact"
                  :key="selectedContactKey"
                  label="fullname"
                  :disabled="selectedContactIsDisabled"
                  :resource="$data.$constants.RESOURCES.RESOURCE_CONTACTS"
                  :http-body="{ client_id: clientId }"
                  :value="model.contact"
                  @input="update('contact', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Referencia de cliente"
                label-for="sale-invoice-client-ref"
              >
                <b-form-input
                  id="sale-invoice-client-ref"
                  :value="model.ref_client"
                  @input="update('ref_client', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Job"
                label-for="sale-invoice-job"
              >
                <BaseSelect
                  id="sale-invoice-job"
                  :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
                  :value="model.job"
                  :clearable="false"
                  @selected="handleJobSelect"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="showSelectedContract || model.job_contract || isMaintenance "
              sm="12"
            >
              <validation-provider
                v-slot="validationContext"
                name="Contrato"
                rules="required"
              >
                <b-form-group
                  label="Contrato"
                  label-for="sale-invoice-contract"
                >
                  <BaseSelect
                    id="sale-invoice-contract"
                    :key="selectedContractKey"
                    label="name"
                    :value="model.job_contract"
                    :resource="$data.$constants.RESOURCES.RESOURCE_JOBS_CONTRACTS"
                    :disabled="!model.job"
                    :http-body="{
                      job_id: jobId,
                    }"
                    :clearable="false"
                    @input="update('job_contract', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="3">
              <b-form-group
                label="Retención"
                label-for="sale-invoice-retention"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="sale-invoice-retention"
                    :value="model.retention_percent"
                    placeholder="Retención"
                    type="number"
                    @input="update('retention_percent', $event)"
                  />
                  <b-input-group-append is-text>
                    %
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="5">
              <b-form-group
                label="Vencimiento retención"
                label-for="sale-invoice-retention-due-date"
              >
                <b-form-datepicker
                  id="sale-invoice-retention-due-date"
                  :value="model.retention_expiration"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Vencimiento retención"
                  :label-help="null"
                  start-weekday="1"
                  @input="update('retention_expiration', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="model.collateral_retention_collected"
                value="1"
                :unchecked-value="0"
                @input="update('collateral_retention_collected', $event)"
              >
                Retención por garantía cobrada
              </b-form-checkbox>
            </b-col>
            <b-col
              sm="12"
              class="pb-2"
            >
              <b-form-checkbox
                v-model="model.withholding_document_received"
                value="1"
                :unchecked-value="0"
                @input="update('withholding_document_received', $event)"
              >
                Documento de retención recibido
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Observaciones"
                label-for="sale-invoice-observations"
              >
                <b-form-textarea
                  :value="model.observations"
                  name="sale-invoice-observations"
                  placeholder="Observaciones"
                  rows="4"
                  max-rows="4"
                  @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import ApiRestService from '@/api/base-api'
import { SALE_INVOICES_STATUS_TYPE } from '@/api/status-api'
import SaleInvoicesApi from '@/api/sale-invoices-api'
import JobsApi from '@/api/jobs-api'
import FormMixin from '@/mixins/formMixin'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import ClientSelect from '@/components/clients/select/ClientSelect.vue'

export default {
  name: 'SaleInvoicesProfileForm',
  components: {
    ClientSelect,
    BaseSelect,
    StatusSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      series: [],
      statusType: SALE_INVOICES_STATUS_TYPE,
      saleInvoiceTypes: [
        { text: 'Abono', value: 'R' },
        { text: 'Factura', value: 'F' },
      ],
      numberInputState: true,
      selectedContactIsDisabled: true,
      selectedContactKey: null,
      showSelectedContract: false,
      selectedContractKey: null,
    }
  },
  computed: {
    saleInvoiceCategory() {
      return this.value?.sale_invoice_category
    },
    numberInputDisabled() {
      return !this.value?.serie
    },
    clientId() {
      return this.model?.client?.id
    },
    jobId() {
      return this.model?.job?.id
    },
    isMaintenance() {
      return this.model?.division?.group == 'M'
    },
  },
  watch: {
    async clientId(newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        this.update('contact', null)
      }
      this.selectedContactIsDisabled = true
      if (newValue) {
        const response = await ApiRestService.list({ filters: { client_id: this.clientId } }, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        if (response.data.length) {
          this.selectedContactIsDisabled = false
          this.selectedContactKey = `${new Date()}`
        }
      }
    },
    jobId(value) {
      if (value) {
        this.handleJobSelect(this.model.job)
      }
    },
  },
  methods: {
    validateForm() {
      return this.$refs['sale-invoice-profile-form'].validate()
    },
    async handleSaleInvoiceCategoryChange() {
      await this.loadSeries()
      await this.getNumber()
    },
    async loadSeries() {
      const response = await SaleInvoicesApi.listSeries(this.saleInvoiceCategory)
      this.series = response.series
    },
    async getNumber() {
      if (!this.value?.serie) {
        this.update('number', null)

        return
      }

      const response = await SaleInvoicesApi.getSaleInvoiceNumber(this.saleInvoiceCategory, this.value?.serie, this.value?.id)
      this.update('number', response.number)
    },
    handleInputSerie(serie) {
      this.update('serie', serie)
      setTimeout(() => this.getNumber(), 0)
    },
    focusNumberAndSerieError() {
      this.$refs['sale-invoice-number'].focus()
      this.numberInputState = false
    },
    async handleJobSelect(job) {
      this.update('job', job)
      this.model.job = job

      const response = await JobsApi.get(job.id)
      const {
        client, division, type_payment, job_contracts, ref_client,
      } = response.data

      if (division.reference_code == 'M') {
        const contractActive = job_contracts.find(({ active }) => active === 1)

        this.model.job_contract = contractActive || {}
        this.update('job_contract', contractActive || {})
        this.showSelectedContract = true

        this.update('ref_client', contractActive.ref_client || null)
        this.model.ref_client = contractActive.ref_client || null
      } else {
        this.showSelectedContract = false
        this.model.job_contract = null
        this.update('job_contract', null)

        this.update('ref_client', ref_client)
        this.model.ref_client = ref_client
      }

      this.selectedContractKey = `${new Date()}`

      this.model.division = division
      this.update('division', division)

      this.model.client = client
      this.update('client', client)

      this.update('type_payment', type_payment)
    },
  },
}
</script>
